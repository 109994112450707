// const image1 = require('../imgs/image1.JPG')
const image2 = require('../imgs/image2.JPG')
const image3 = require('../imgs/image3.JPG')
const image4 = require('../imgs/image4.JPG')
const image5 = require('../imgs/image5.JPG')


export const carouselImages = () => {
    
    return ([

        // {
        //     'image':image1,
        //     'key':1
        // },
        {
            'image':image2,
            'key':2
        },
        {
            'image':image3,
            'key':3
        },
        {
            'image':image4,
            'key':4
        },
        {
            'image': image5,
            'key':5
        }
])}